<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Группы эдвайзера</h4>

      <!-- list of courses -->
      <div>

        <div v-for="(adviser, adviserIndex) in advisers" :key="adviserIndex" class="mt-2 mb-5">
          <h5 class="mt-1 mb-4 text-center">
            {{adviser?.teacher_lastname}} {{adviser?.teacher_firstname}} {{adviser?.teacher_middlename}}
          </h5>

          <div v-for="(group, groupIndex) in adviser.groups" :key="groupIndex" class="mb-5">
            <h5 class="my-2 text-center">
              {{group?.student_groups_name}}
              <router-link v-if="group?.study_course!=4"
                           :to="`/adviser/discipline-selection-statistics?group_id=${group?.student_groups_id}`"
                           target="_blank">
                Статистика выбора дисциплин
              </router-link>
            </h5>

            <div>
              <DataTable :value="group.students" showGridlines stripedRows responsiveLayout="scroll">
                <Column header="ФИО">
                  <template #body="{data}">
                    {{data.last_name}} {{data.first_name}} {{data.middle_name}}
                  </template>
                </Column>
                <!-- actions -->
                <Column header="Действия">
                  <template #body="{data}">
                    <ul v-if="group?.study_course!=4">
                      <li>
                        <router-link :to="`/student/choose-disciplines?barcode=${data.barcode}`" target="_blank">
                          ИУП
                        </router-link>
                      </li>
                    </ul>
                  </template>
                </Column>
                <!-- end actions -->
              </DataTable>

            </div>
          </div>

        </div>

      </div>
      <!-- end list of courses -->


    </div>
  </div>
</template>


<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"

  export default {
    name: "AdviserGroups",
    data() {
      return {
        loading: true,
      }
    },
    computed: {
      ...mapState('adviser', ['advisers']),
    },

    methods: {
      ...mapActions('adviser', ['GET_STUDENTS_BY_ADVISER']),

    },
    async mounted() {
      await this.GET_STUDENTS_BY_ADVISER()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>